export const pushData = ({ form_name, lease_type, signup, value, item, user_data }) => {
  const data = {
    event: 'generate_lead',
    form_name,
    signup,
    user_data: {
      email: user_data.email,
      phone_number: user_data.phone_number,
    },
  };

  if (item) {
    data.item = [
      {
        item_name: item.item_name,
        item_brand: item.item_brand,
        item_variant: item.item_variant,
        item_category: item.item_category,
        item_category2: item.item_category2,
        item_category3: item.item_category3,
        price: item.price,
        quantity: 1,
        duration: item.duration,
        annual_miles: item.annual_miles,
        initial_payment_duration: item.initial_payment_duration,
        initial_payment_price: item.initial_payment_price,
        maintenance: item.maintenance,
        maintenance_price: item.maintenance_price,
        item_list_name: window.localStorage.getItem('listPath'),
        item_list_id: window.localStorage.getItem('listID'),
      },
    ];
    data.lease_type = lease_type;
    data.value = value;
    data.currency = 'GBP';
  }

  dataLayer.push(data);

  window.localStorage.removeItem('listPath');
  window.localStorage.removeItem('listID');
};
