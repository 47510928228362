export const pushData = ({ step, stepName, action, currentValue }) => {
  const data = {
    event: 'eligibility_checker',
    step: step + 1,
    step_name: stepName,
    action,
    selection: currentValue || '',
  };
  dataLayer.push(data);
};
