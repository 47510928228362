import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import Rails from '@rails/ujs';

let targetID = '';
// eslint-disable-next-line no-unused-vars
let closeTimeout;

export default class extends Controller {
  static targets = ['container'];

  static debounces = ['request'];

  connect() {
    useDebounce(this);
    this.handleBeforeCache();
  }

  disconnect() {
    this.close();
    this.removeBootstrapModalClass();
    this.clearModalContent();
  }

  close(event) {
    if (event) {
      event.preventDefault();
    }
    this.getElement().modal('hide');
  }

  getElement() {
    const selector = targetID ? `#${targetID}` : '.modal';
    return $(selector, this.containerTarget);
  }

  show(event) {
    event.preventDefault();

    targetID = event.target.getAttribute('data-target');
    const modal = document.getElementById(targetID);

    this.containerTarget.innerHTML = modal.outerHTML;

    this.getElement()
      .show()
      .modal('show')
      .on('hide.bs.modal', () => {
        this.removeBootstrapModalClass();
      });
  }

  load(event) {
    event.preventDefault();

    switch (event.currentTarget.nodeName.toLowerCase()) {
      case 'a':
        this.request(event.currentTarget.getAttribute('data-path'), 'GET', '');
        break;
      case 'form':
        this.request(event.target.action, 'POST', new FormData(event.currentTarget));
        break;
      default:
    }
  }

  request(href, type, data) {
    Rails.ajax({
      url: href,
      type,
      dataType: 'json',
      data,
      accept: 'application/json',
      success: (result) => {
        this.populate(result.html);
      },
      error: (xhr, status, error) => {
        console.log(error);
      },
    });
  }

  removeBootstrapModalClass() {
    document.body.classList.remove('has-modal', 'modal-open');
    $('.modal-backdrop').remove(); // Remove any modal backdrops
  }

  setHtml(html) {
    ReactRailsUJS.unmountComponents(this.containerTarget);
    this.containerTarget.innerHTML = html;
  }

  populate(html) {
    $('.modal-backdrop').remove(); // Remove any modal backdrops

    // Add html response to modal container
    this.setHtml(html);

    // Load react-rails components after ajax request
    ReactRailsUJS.mountComponents(this.containerTarget);
    this.getElement()
      .modal('show')
      .on('shown.bs.modal', () => {
        document.body.classList.add('has-modal', 'modal-open');
      })
      .on('hide.bs.modal', () => {
        this.removeBootstrapModalClass();
        closeTimeout = setTimeout(() => {
          this.clearModalContent();
        }, 0);
      });
  }

  handleBeforeCache() {
    document.addEventListener('turbo:before-cache', () => {
      this.close();
      this.removeBootstrapModalClass();
      this.clearModalContent();
    });
  }

  clearModalContent() {
    this.containerTarget.innerHTML = '';
  }
}
