import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

let currentID = '';

export default class extends Controller {
  static targets = ['container'];

  close(event) {
    event.preventDefault();

    // Check if only one drawer open and update current ID for this
    if ($('[data-drawer-content]').length === 1) {
      currentID = $('[data-drawer-content]')[0].id;
    }

    // Wait for bootstrap plugin animation to finish
    setTimeout(() => {
      $(`#${currentID}`).closest('[data-drawer-container]').empty();

      // If no drawers are open remove has-drawer class
      if (!$('[data-drawer-content]').length) {
        document.body.classList.remove('has-drawer');
      }
    }, 100);
  }

  load(event, isNew) {
    event.preventDefault();
    currentID = event.currentTarget.getAttribute('data-target');

    switch (event.currentTarget.nodeName.toLowerCase()) {
      case 'a':
        this.request(event.currentTarget.getAttribute('data-path'), 'GET', '', isNew);
        break;
      case 'form':
        this.request(event.currentTarget.action, 'POST', new FormData(event.currentTarget), isNew);
        break;
      default:
    }
  }

  request(href, type, data, isNew) {
    Rails.ajax({
      url: href,
      type,
      dataType: 'json',
      data,
      accept: 'application/json',
      success: (result) => {
        this.populate(result.html, isNew);
      },
      error: (xhr, status, error) => {
        console.log(error);
      },
    });
  }

  populate(html, isNew) {
    const $drawers = $('[data-drawer-container]');
    document.body.classList.add('has-drawer');

    if (isNew) {
      if ($(`#${isNew}`).length === 0) {
        // Inject html for new container with id
        $drawers[$drawers.length - 1].insertAdjacentHTML('afterend', `<div data-drawer-container id="${isNew}"></div>`);
      }
      $(`#${isNew}`)[0].insertAdjacentHTML('afterbegin', html);
    } else {
      // Add html response to modal container
      this.containerTarget.innerHTML = html;
    }

    // Initialise & open drawer programmatically
    $(`#${currentID}`).drawer().drawer('open');

    // Drawer close event listener
    $(`#${currentID}`).on('drawer.closed', (event) => {
      this.close(event);
    });

    // Load react-rails components after ajax request
    ReactRailsUJS.mountComponents();
  }

  createNewDrawer(event) {
    event.preventDefault();
    // Create new drawer container
    const drawerName = event.currentTarget.dataset.id;

    this.load(event, `${drawerName}-drawer`);
  }
}
