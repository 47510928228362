import { Controller } from '@hotwired/stimulus';

import { formToQueryString } from '../../utils';

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', () => {
      if (this.element.getAttribute('data-show-auto-submit-loader') === 'false') {
        return;
      }

      Array.from(this.element.children).forEach((child) => child.classList.add('is-loading'));

      const loader = document.createElement('div');
      loader.id = 'loader';

      this.element.appendChild(loader);
    });
  }

  change(event) {
    if (this.element.getAttribute('data-remote') === 'true') {
      this.element.dispatchEvent(new Event('submit', { bubbles: true }));

      const additionalParams = {};

      // Handle submit buttons or inputs that have names and values
      if (event.target.nodeName === 'BUTTON' && event.target.name && typeof event.target.value !== 'undefined') {
        additionalParams[event.target.name] = event.target.value;
      }

      if (event.target.nodeName !== 'INPUT' || event.target.type !== 'checkbox') {
        window.history.pushState(
          { turbolinks: {} },
          '',
          `${window.location.pathname}?${formToQueryString(this.element, additionalParams)}`,
        );
      }
    } else {
      this.element.submit();
    }
  }
}
