export const categoriesColour = [
  44, // Paint - Metallic
  45, // Paint - Solid
  73, // Paint - Mica
  74, // Paint - Pearlescent
  75, // Paint - Special
  76, // Paint - Two Tone
  103, // Paint - Metallic
  104, // Paint - Mica
  105, // Paint - Pearlescent
  106, // Paint - Solid
  107, // Paint - Special
];

export const categoriesEquipment = [
  46, // Driver Convenience
  48, // Security
  64, // Engine
  65, // Transmission
  67, // Driver Aids
  68, // Communication
  77, // Safety
  79, // Towing
  89, // Wheels - Spare
  95, // PTO
  99, // Security
  101, // Electrical
  102, // Load Area
  116, // Driver Aids
];

export const categoriesExterior = [
  26, // Wheels
  58, // Exterior Body Features
  59, // Chassis/Suspension
  60, // Hoods
  62, // Body Glass
  63, // Brakes
  69, // Driving Mirrors
  71, // Exterior Lights
  88, // Wheels - Alloy
  96, // Doors
  98, // Wheels - Alloys
];

export const categoriesInterior = [
  24, // Entertainment
  49, // Seats
  61, // Interior Features
  66, // Driver Information
  70, // Interior Lights
  72, // Vanity Mirrors
  80, // Carpets/Rugs
  87, // Heating/Cooling/Ventilation
  100, // Driver Information
  114, // Interior Lights
  115, // Communication
];

export const categoriesPacks = [34];

export const categoriesTrim = [
  81, // Trim - Cloth
  82, // Trim - Leather
  83, // Seat Piping/Additional Trimming
  84, // Embellishment Trims
  86, // Trim - Alcantara Suede
  90, // Trim - Vinyl
  97, // both Trim - Part Leather, and Bulkhead/Partition
  109, // Trim - Alcantara
  110, // Trim - Cloth
  111, // Trim - Leather
  112, // Trim - Part Leather
  113, // Trim - Vinyl
];

export const allCategories = [
  ...categoriesColour,
  ...categoriesEquipment,
  ...categoriesExterior,
  ...categoriesInterior,
  ...categoriesPacks,
  ...categoriesTrim,
];
