import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['anchor'];

  connect() {
    if (this.hasAnchorTarget) {
      this.element.style.cursor = 'pointer';

      this.element.addEventListener('click', (event) => {
        if (event.target.nodeName.toLowerCase() !== 'a') {
          Turbolinks.visit(this.anchorTarget.href);
        }
      });
    }
  }
}
