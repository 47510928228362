import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  toggle(event) {
    const toggleClass = this.element.dataset.dropdownToggleClass || this.getParentToggleClass();

    if (event.target.tagName === 'INPUT' && event.target.type === 'radio') {
      setTimeout(() => {
        const parentDropdown = this.getClosestDropdownElement(event.target);
        this.toggleClass(toggleClass, parentDropdown);
      }, 200);
    } else {
      this.toggleClass(toggleClass, this.element);
    }
  }

  close() {
    setTimeout(() => {
      const toggleClass = this.element.dataset.dropdownToggleClass || this.getParentToggleClass();
      this.element.classList.remove(toggleClass);
    }, 200);
  }

  handleClickOutside(event) {
    const dropdown = this.element;
    const toggleClass = this.element.dataset.dropdownToggleClass || this.getParentToggleClass();

    if (!dropdown.contains(event.target) && dropdown.classList.contains(toggleClass)) {
      dropdown.classList.remove(toggleClass);
    }
  }

  toggleClass(toggleClass, element) {
    if (toggleClass && element) {
      element.classList.toggle(toggleClass);
    }
  }

  getParentToggleClass() {
    const parentElement = this.element.closest('.drv-ui.drv-filter-controls__sort');
    return parentElement ? parentElement.dataset.dropdownToggleClass : null;
  }

  getClosestDropdownElement(target) {
    return target.closest('.drv-ui.drv-filter-controls__sort');
  }
}
